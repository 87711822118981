import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "customCurrency" })
export class CustomCurrencyPipe implements PipeTransform {
  transform(value: number): string {
    const integerPart = Math.floor(value);
    const decimalPart = value % 1;
    if (decimalPart > 0) {
      // Si el número tiene decimales significativos, los mostramos
      return `$${integerPart.toLocaleString("es-MX")}.${decimalPart.toFixed(2).slice(2)}`;
    } else {
      // Si el número no tiene decimales significativos, solo mostramos la parte entera
      return `$${integerPart.toLocaleString("es-MX")}`;
    }
  }
}
